module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-reference-footnotes","options":{}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Inhaltsverzeichnis|Table of Contents","tight":false,"fromHeading":2,"toHeading":6}},{"resolve":"gatsby-remark-copy-linked-files"},"gatsby-remark-slug",{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1800,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"backgroundColor":"transparent"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
