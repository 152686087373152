// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-colors-index-md": () => import("./../src/pages/articles/Colors/index.md" /* webpackChunkName: "component---src-pages-articles-colors-index-md" */),
  "component---src-pages-articles-wooden-letters-index-md": () => import("./../src/pages/articles/WoodenLetters/index.md" /* webpackChunkName: "component---src-pages-articles-wooden-letters-index-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-2018-11-05-pin-marks-index-md": () => import("./../src/pages/posts/2018-11-05--pin-marks/index.md" /* webpackChunkName: "component---src-pages-posts-2018-11-05-pin-marks-index-md" */),
  "component---src-pages-posts-2018-11-16-transformation-index-md": () => import("./../src/pages/posts/2018-11-16--transformation/index.md" /* webpackChunkName: "component---src-pages-posts-2018-11-16-transformation-index-md" */),
  "component---src-pages-posts-2018-12-03-cut-out-index-md": () => import("./../src/pages/posts/2018-12-03--cut-out/index.md" /* webpackChunkName: "component---src-pages-posts-2018-12-03-cut-out-index-md" */),
  "component---src-pages-posts-2019-04-15-typometer-index-md": () => import("./../src/pages/posts/2019-04-15-typometer/index.md" /* webpackChunkName: "component---src-pages-posts-2019-04-15-typometer-index-md" */),
  "component---src-pages-posts-2020-04-08-type-founding-index-md": () => import("./../src/pages/posts/2020-04-08--type-founding/index.md" /* webpackChunkName: "component---src-pages-posts-2020-04-08-type-founding-index-md" */),
  "component---src-pages-posts-2020-05-01-adana-index-md": () => import("./../src/pages/posts/2020-05-01--adana/index.md" /* webpackChunkName: "component---src-pages-posts-2020-05-01-adana-index-md" */),
  "component---src-pages-root-md": () => import("./../src/pages/root.md" /* webpackChunkName: "component---src-pages-root-md" */),
  "component---themes-gatsby-theme-signalwerk-src-pages-404-js": () => import("./../../../themes/gatsby-theme-signalwerk/src/pages/404.js" /* webpackChunkName: "component---themes-gatsby-theme-signalwerk-src-pages-404-js" */),
  "component---themes-gatsby-theme-signalwerk-src-templates-post-index-js": () => import("./../../../themes/gatsby-theme-signalwerk/src/templates/Post/index.js" /* webpackChunkName: "component---themes-gatsby-theme-signalwerk-src-templates-post-index-js" */)
}

